import { Modal, Button, Form, Input, Select, Checkbox } from 'antd';
import React, { useState } from 'react';

const { Option } = Select;

const EditModal = ({ visible, onCancel, onSave, record, fields, title, formRef }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false); // 增加 loading 状态

  React.useEffect(() => {
    if (record) {
      form.setFieldsValue(record);
    } else {
      form.resetFields();
    }
    if (formRef) {
      formRef.current = form; // 设置 formRef 的当前表单实例
    }
  }, [record, form, formRef]);

  const handleSave = () => {
    form.validateFields()
      .then((values) => {
        setLoading(true); // 开始加载状态
        onSave(values).finally(() => {
          setLoading(false); // 保存操作完成后关闭加载状态
        });
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  };

  return (
    <Modal
      title={title}  // 使用传入的 title 属性
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button key="back" onClick={onCancel}>
          取消
        </Button>,
        <Button key="submit" type="primary" onClick={handleSave} loading={loading}>
          保存
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical">
        {fields.map((field) => (
          <React.Fragment key={field.name || field.label}>
            {field.type === 'group' ? (
              <div style={{ marginBottom: '16px' }}>
                <strong>{field.label}</strong>
              </div>
            ) : (
              <Form.Item
                label={field.type === 'checkbox' ? '' : field.label} // 如果是checkbox，不显示label
                name={field.name}
                rules={field.rules}
                valuePropName={field.type === 'checkbox' ? 'checked' : 'value'}
                initialValue={field.initialValue}
              >
                {field.type === 'select' ? (
                  <Select
                    showSearch
                    placeholder={field.placeholder}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children.toLowerCase().includes(input.toLowerCase())
                    }
                  >
                    {field.options.map(option => (
                      <Option key={option.value} value={option.value}>
                        {option.label}
                      </Option>
                    ))}
                  </Select>
                ) : field.type === 'checkbox' ? (
                  <Checkbox>{field.label}</Checkbox>
                ) : (
                  <Input placeholder={field.placeholder} />
                )}
              </Form.Item>
            )}
          </React.Fragment>
        ))}
      </Form>
    </Modal>
  );
};

export default EditModal;
