import { useState, useEffect, useRef } from 'react';
import CustomTable from '../../components/CustomTable';
import EditModal from '../../components/EditModal'; // 引入EditModal组件
import request from '../../utils/request';
import { useAppContext } from '../../utils/appContext';
import { Card, Button, Skeleton, Modal } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Message } from '@shoplinedev/appbridge';

const FacebookPixel = () => {
  const [historyData, setHistoryData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingRecord, setEditingRecord] = useState(null);
  const [isEditing, setIsEditing] = useState(false); // 用于区分添加和编辑操作
  const [loading, setLoading] = useState(true); // 初始化加载状态为true
  const formRef = useRef(); // 增加一个引用来重置表单
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(0);

  const fields = [
    { name: 'pixel', label: 'Facebook像素', rules: [{ required: true, message: '请输入Facebook像素' }], placeholder: 'Facebook像素' },
    { name: 'token', label: 'Conversion API Access Token', rules: [{ required: true, message: '请输入Conversion API Access Token' }] , placeholder: '请输入Conversion API Access Token' },
    { type: 'group', label: '事件类型' },
    { name: 'pageView', label: '页面浏览', type: 'checkbox', initialValue: true },
    { name: 'addToCart', label: '加入购物车', type: 'checkbox', initialValue: true },
    { name: 'viewContent', label: '产品浏览', type: 'checkbox', initialValue: true },
    { name: 'initiateCheckout', label: '发起结账', type: 'checkbox', initialValue: true },
    { name: 'purchase', label: '购物成效', type: 'checkbox', initialValue: true },
    { name: 'addPaymentInfo', label: '添加支付方式', type: 'checkbox' }
  ];

  // app实例
  const { app, token } = useAppContext();
  const message = Message.create(app);

  const fetchData = async () => {
    try {
      const startTime = Date.now(); // 记录开始时间
      const data = await request.get("v1/api/merchants/facebookPixel", {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      const processedData = data.data.map((item) => ({
        key: item.id,
        pixel: item.pixel,
        token: item.token,
        status: item.status,
        id: item.id,
        pageView: item.pageView === 1,
        addToCart: item.addToCart === 1,
        viewContent: item.viewContent === 1,
        initiateCheckout: item.initiateCheckout === 1,
        purchase: item.purchase === 1,
        addPaymentInfo:item.addPaymentInfo === 1
      }));
      const elapsed = Date.now() - startTime;
      const minLoadingTime = 500; // 最小加载时间（毫秒）

      if (elapsed < minLoadingTime) {
        setTimeout(() => {
          setHistoryData(processedData);
          setLoading(false); // 数据加载完成，设置加载状态为false
        }, minLoadingTime - elapsed);
      } else {
        setHistoryData(processedData);
        setLoading(false); // 数据加载完成，设置加载状态为false
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [app, token]);

  const showEditModal = (record) => {
    setEditingRecord(record);
    setIsEditing(true);
    setIsModalVisible(true);
    setTimeout(() => {
      formRef.current?.setFieldsValue(record); // 设置表单中的值
    }, 0);
  };

  const showAddModal = () => {
    setEditingRecord(null);
    setIsEditing(false);
    setIsModalVisible(true);
    setTimeout(() => {
      formRef.current?.resetFields(); // 清除表单中的所有值
    }, 0);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setEditingRecord(null);
  };

  const handleSave = async (values) => {
    if (!values.pixel || !values.token) {
      return;
    }

    // 转换布尔值为1/0
    const payload = {
      ...values,
      pageView: values.pageView ? 1 : 0,
      addToCart: values.addToCart ? 1 : 0,
      viewContent: values.viewContent ? 1 : 0,
      initiateCheckout: values.initiateCheckout ? 1 : 0,
      purchase: values.purchase ? 1 : 0,
      addPaymentInfo: values.addPaymentInfo ? 1 : 0
    };

    try {
      if (isEditing) {
        // 编辑操作
        const res = await request.post("v1/api/merchants/facebookPixel", {
          ...payload,
          action: 'update',
          id: editingRecord.id 
        }, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        message.open({
          type: res.code === 200 ? 'success' : 'error',
          messageInfo: res.code === 200 ? '更新成功' : '更新失败'
        });
      } else {
        // 添加操作
        const res = await request.post("v1/api/merchants/facebookPixel", {
          ...payload,
          action: 'insert'
        }, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        message.open({
          type: res.code === 200 ? 'success' : 'error',
          messageInfo: res.code === 200 ? '添加成功' : '添加失败'
        });
      }
      setIsModalVisible(false);
      fetchData(); // 重新获取数据
    } catch (error) {
      console.error("Error saving data: ", error);
    }
  };

  const handleDelete = async (key) => {
    try {
      const res = await request.post("v1/api/merchants/facebookPixel", {
        action: 'delete',
        id: key
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      message.open({
        type: res.code === 200 ? 'success' : 'error',
        messageInfo: res.code === 200 ? '删除成功' : '删除失败'
      });
      setHistoryData(historyData.filter((item) => item.key !== key));
    } catch (error) {
      console.error("Error deleting data: ", error);
    }
  };

  const showDeleteConfirm = (key) => {
    Modal.confirm({
      title: '确认删除',
      content: '你确定要删除这条记录吗?',
      okText: '是',
      okType: 'danger',
      cancelText: '否',
      onOk() {
        handleDelete(key);
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  // 表格列
  const columns = [
    { title: "Facebook像素", dataIndex: "pixel" },
    { title: "Conversion API Access Token", dataIndex: "token" },
    {
      title: "状态",
      dataIndex: "status",
      render: (text, record) => (
        <span
          className={record.status === 1 ? "ant-tag sl-tag sl-tag-success sl-tag-dot sl-tag-round" : "ant-tag sl-tag sl-tag-error sl-tag-dot sl-tag-round"}
        >
          {record.status === 1 ? "验证成功" : "验证失败"}
        </span>
      )
    },
    {
      title: "操作",
      dataIndex: "action",
      render: (text, record) => (
        <>
          <EditOutlined onClick={() => showEditModal(record)} style={{ marginRight: 8 }} />
          <DeleteOutlined style={{color:"#FF522F"}} onClick={() => showDeleteConfirm(record.id)} />
        </>
      ),
      width: 100,
    },
  ];

  return (
    <div className="flex justify-center items-start pt-8">
      <div className="w-2/3">
        <Card title="Pixel 和 Conversion API" extra={<Button type="primary" onClick={showAddModal}>新增像素</Button>}>
        <Skeleton loading={loading} active>
          <div className="flex">
            <div className="w-1/3 p-4">
             
                <h3 className="text-xl font-bold">Facebook Pixel</h3>
                <p className="text-gray-600">Facebook Pixel可以帮助你创建广告活动，找到看起来最像你的买家的新客户</p>
            </div>
            <div className="w-2/3 p-4">

                <CustomTable
                  dataIndex={columns}
                  dataSource={historyData}
                  pagination={{
                    current: currentPage,
                    pageSize: pageSize,
                    total: total,
                    pageSizeOptions: ['10', '20', '50', '100'], 
                    showTotal: (total, range) => `总共 ${total} 条数据`,
                    onChange: (page, pageSize) => {
                      setCurrentPage(page);
                      setPageSize(pageSize);
            
                    },
                  }}
                />

            </div>
          </div>
       </Skeleton>
        </Card>
      </div>
      <EditModal
        title={isEditing ? "编辑像素" : "添加像素"}
        visible={isModalVisible}
        onCancel={handleCancel}
        onSave={handleSave}
        record={editingRecord}
        fields={fields}
        formRef={formRef} 
      />
    </div>
  );
};

export default FacebookPixel;
