import { Oauth } from '@shoplinedev/appbridge';
import { createAppInstance } from "../../utils/appbridgeUtils";

const CheckOauth = () => {
    try {
    const app = createAppInstance();
    const appKey = '1994b2c946a3528b1df2351e3592ca34c3418fea';
    const redirectUri = 'https://wenorod.shop/callback';
    const scope = 'read_orders,write_themes,write_customers,read_draft_orders,write_discounts,write_bulkoperation,read_gift_card,write_draft_orders,write_translation,read_products,write_inventory,write_product_listings,write_shipping,read_page,read_themes,read_store_metrics,read_files,write_price_rules,read_shipping,read_translation,read_customers,write_shipping,read_product_listings,write_store_information,read_payment,write_script_tags,read_store_information,write_page,write_orders,read_script_tags';

        
        Oauth.create(app).invoke({
            scope,
            appKey,
            redirectUri
        });
    } catch (error) {
        console.error("OAuth invocation error: ", error);
    }
}

export default CheckOauth;
