// axios封装
import axios from "axios";
// import {getToken} from "./token";

const request = axios.create({
    baseURL:"https://wenorod.shop/",
    // baseURL:"https://127.0.0.1/",
    timeout:30000
})

// 添加请求拦截器
// request.interceptors.request.use((config)=> {
//     const token = getToken()
//     if(token){
//         config.headers.Authorization = `Bearer ${token}`
//     }
//     return config
// }, (error)=> {
//     return Promise.reject(error)
// })

// 添加响应拦截器
request.interceptors.response.use((response)=> {
    return response.data
}, (error)=> {


    return Promise.reject(error)
})
export  default request
