// src/utils/timezones.js

const timezones = [
    { value: "US/Samoa", label: "(Etc/GMT-11) US/Samoa" },
    { value: "Pacific/Honolulu", label: "(Etc/GMT-10) Pacific/Honolulu" },
    { value: "America/Adak", label: "(Etc/GMT-10) America/Adak" },
    { value: "US/Hawaii", label: "(Etc/GMT-10) US/Hawaii" },
    { value: "America/Nome", label: "(Etc/GMT-9) America/Nome" },
    { value: "America/Anchorage", label: "(Etc/GMT-9) America/Anchorage" },
    { value: "US/Alaska", label: "(Etc/GMT-9) US/Alaska" },
    { value: "Canada/Pacific", label: "(Etc/GMT-8) Canada/Pacific" },
    { value: "Canada/Yukon", label: "(Etc/GMT-8) Canada/Yukon" },
    { value: "America/Los_Angeles", label: "(Etc/GMT-8) America/Los_Angeles" },
    { value: "US/Pacific", label: "(Etc/GMT-8) US/Pacific" },
    { value: "America/Inuvik", label: "(Etc/GMT-7) America/Inuvik" },
    { value: "US/Arizona", label: "(Etc/GMT-7) US/Arizona" },
    { value: "America/Ojinaga", label: "(Etc/GMT-7) America/Ojinaga" },
    { value: "America/Boise", label: "(Etc/GMT-7) America/Boise" },
    { value: "Canada/Mountain", label: "(Etc/GMT-7) Canada/Mountain" },
    { value: "America/Guatemala", label: "(Etc/GMT-6) America/Guatemala" },
    { value: "America/Belize", label: "(Etc/GMT-6) America/Belize" },
    { value: "Pacific/Easter", label: "(Etc/GMT-6) Pacific/Easter" },
    { value: "America/Chicago", label: "(Etc/GMT-6) America/Chicago" },
    { value: "Canada/Central", label: "(Etc/GMT-6) Canada/Central" },
    { value: "Canada/Saskatchewan", label: "(Etc/GMT-6) Canada/Saskatchewan" },
    { value: "America/Iqaluit", label: "(Etc/GMT-5) America/Iqaluit" },
    { value: "America/Panama", label: "(Etc/GMT-5) America/Panama" },
    { value: "America/Indiana/Petersburg", label: "(Etc/GMT-5) America/Indiana/Petersburg" },
    { value: "US/Eastern", label: "(Etc/GMT-5) US/Eastern" },
    { value: "Canada/Eastern", label: "(Etc/GMT-5) Canada/Eastern" },
    { value: "America/Toronto", label: "(Etc/GMT-5) America/Toronto" },
    { value: "Cuba", label: "(Etc/GMT-5) Cuba" },
    { value: "Brazil/West", label: "(Etc/GMT-4) Brazil/West" },
    { value: "America/Manaus", label: "(Etc/GMT-4) America/Manaus" },
    { value: "Chile/Continental", label: "(Etc/GMT-4) Chile/Continental" },
    { value: "America/Santiago", label: "(Etc/GMT-4) America/Santiago" },
    { value: "Canada/Atlantic", label: "(Etc/GMT-4) Canada/Atlantic" },
    { value: "Atlantic/Bermuda", label: "(Etc/GMT-4) Atlantic/Bermuda" },
    { value: "Canada/Newfoundland", label: "(Etc/GMT-3.30) Canada/Newfoundland" },
    { value: "Brazil/East", label: "(Etc/GMT-3) Brazil/East" },
    { value: "America/Recife", label: "(Etc/GMT-3) America/Recife" },
    { value: "America/Argentina/Cordoba", label: "(Etc/GMT-3) America/Argentina/Cordoba" },
    { value: "Atlantic/South_Georgia", label: "(Etc/GMT-2) Atlantic/South_Georgia" },
    { value: "Atlantic/Cape_Verde", label: "(Etc/GMT-1) Atlantic/Cape_Verde" },
    { value: "Iceland", label: "(Etc/GMT+0) Iceland" },
    { value: "Europe/London", label: "(Etc/GMT+0) Europe/London" },
    { value: "Atlantic/Canary", label: "(Etc/GMT+0) Atlantic/Canary" },
    { value: "Atlantic/St_Helena", label: "(Etc/GMT+0) Atlantic/St_Helena" },
    { value: "Europe/Berlin", label: "(Etc/GMT+1) Europe/Berlin" },
    { value: "Europe/Paris", label: "(Etc/GMT+1) Europe/Paris" },
    { value: "Europe/Rome", label: "(Etc/GMT+1) Europe/Rome" },
    { value: "Africa/Luanda", label: "(Etc/GMT+1) Africa/Luanda" },
    { value: "Africa/Cairo", label: "(Etc/GMT+2) Africa/Cairo" },
    { value: "Atlantic/Jan_Mayen", label: "(Etc/GMT+2) Atlantic/Jan_Mayen" },
    { value: "Europe/Moscow", label: "(Etc/GMT+3) Europe/Moscow" },
    { value: "Asia/Tehran", label: "(Etc/GMT+3.30) Asia/Tehran" },
    { value: "Asia/Dubai", label: "(Etc/GMT+4) Asia/Dubai" },
    { value: "Asia/Kabul", label: "(Etc/GMT+4.30) Asia/Kabul" },
    { value: "Asia/Karachi", label: "(Etc/GMT+5) Asia/Karachi" },
    { value: "Asia/Calcutta", label: "(Etc/GMT+5.30) Asia/Calcutta" },
    { value: "Asia/Colombo", label: "(Etc/GMT+5.30) Asia/Colombo" },
    { value: "Asia/Kathmandu", label: "(Etc/GMT+5.45) Asia/Kathmandu" },
    { value: "Asia/Almaty", label: "(Etc/GMT+6) Asia/Almaty" },
    { value: "Asia/Rangoon", label: "(Etc/GMT+6.30) Asia/Rangoon" },
    { value: "Asia/Vientiane", label: "(Etc/GMT+7) Asia/Vientiane" },
    { value: "Asia/Jakarta", label: "(Etc/GMT+7) Asia/Jakarta" },
    { value: "Asia/Bangkok", label: "(Etc/GMT+7) Asia/Bangkok" },
    { value: "PRC", label: "(Etc/GMT+8) PRC" },
    { value: "Asia/Hong_Kong", label: "(Etc/GMT+8) Asia/Hong_Kong" },
    { value: "Asia/Kuala_Lumpur", label: "(Etc/GMT+8) Asia/Kuala_Lumpur" },
    { value: "Asia/Chungking", label: "(Etc/GMT+8) Asia/Chungking" },
    { value: "Asia/Macao", label: "(Etc/GMT+8) Asia/Macao" },
    { value: "Asia/Shanghai", label: "(Etc/GMT+8) Asia/Shanghai" },
    { value: "Asia/Macau", label: "(Etc/GMT+8) Asia/Macau" },
    { value: "Asia/Kashgar", label: "(Etc/GMT+8) Asia/Kashgar" },
    { value: "Asia/Taipei", label: "(Etc/GMT+8) Asia/Taipei" },
    { value: "Asia/Manila", label: "(Etc/GMT+8) Asia/Manila" },
    { value: "Asia/Singapore", label: "(Etc/GMT+8) Asia/Singapore" },
    { value: "Asia/Makassar", label: "(Etc/GMT+8) Asia/Makassar" },
    { value: "Asia/Jayapura", label: "(Etc/GMT+9) Asia/Jayapura" },
    { value: "Asia/Seoul", label: "(Etc/GMT+9) Asia/Seoul" },
    { value: "Asia/Tokyo", label: "(Etc/GMT+9) Asia/Tokyo" },
    { value: "Australia/North", label: "(Etc/GMT+9.30) Australia/North" },
    { value: "Australia/South", label: "(Etc/GMT+9.30) Australia/South" },
    { value: "Pacific/Yap", label: "(Etc/GMT+10) Pacific/Yap" },
    { value: "Australia/Canberra", label: "(Etc/GMT+10) Australia/Canberra" },
    { value: "Australia/Melbourne", label: "(Etc/GMT+10) Australia/Melbourne" },
    { value: "Australia/Sydney", label: "(Etc/GMT+10) Australia/Sydney" },
    { value: "Australia/Lindeman", label: "(Etc/GMT+10) Australia/Lindeman" },
    { value: "Australia/Lord_Howe", label: "(Etc/GMT+10.30) Australia/Lord_Howe" },
    { value: "Pacific/Guadalcanal", label: "(Etc/GMT+11) Pacific/Guadalcanal" },
    { value: "Pacific/Fiji", label: "(Etc/GMT+12) Pacific/Fiji" },
    { value: "Pacific/Chatham", label: "(Etc/GMT+12.45) Pacific/Chatham" },
    { value: "Pacific/Tongatapu", label: "(Etc/GMT+13) Pacific/Tongatapu" },
    { value: "Pacific/Kiritimati", label: "(Etc/GMT+14) Pacific/Kiritimati" }
];

export default timezones;
