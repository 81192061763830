import React, { useEffect, useState } from 'react';
import { Menu } from 'antd';
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { createAppInstance } from "../../utils/appbridgeUtils";
import { AppProvider } from '../../utils/appContext';
import { shared } from '@shoplinedev/appbridge';

const items = [
    {
        label: '首页',
        key: '/',
    },
    {
        label: 'Facebook 像素',
        key: '/FacebookPixel',
    },
    {
        label: '广告上报助手',
        key: '/FacebookAds',
    }, 
    {
        label: '追加销售',
        key: '/Upsell',
    },
    {
        label: '支付详情',
        key: '/paymentDetails',
    },
];

const GeekLayout = () => {
    const [token, setToken] = useState(null);
    const app = createAppInstance();
    const navigate = useNavigate();
    const location = useLocation();
    const [error, setError] = useState(null); // 用于存储错误信息
    useEffect(() => {
        const getSessionToken = async () => {
          try {
            const token = await shared.getSessionToken(app);
            setToken(token);
          } catch (error) {
            console.error("Error fetching session token: ", error);
            setError(error.message || 'Error fetching session token');
          }
        };
    
        // 获取一次 token
        getSessionToken();
    
        // 每分钟获取一次 token
        const intervalId = setInterval(getSessionToken, 30000);
    
        // 清除定时器
        return () => clearInterval(intervalId);
      }, [app]);

    // 点击菜单
    const onClickMenu = (router) => {
        navigate(router.key);
    };

    if (!token) {
        return;
    }

    return (
        <AppProvider app={app} token={token}>
            <div>
                <Menu
                    mode="horizontal"
                    theme="light"
                    onClick={onClickMenu}
                    defaultSelectedKeys={['1']}
                    selectedKeys={[location.pathname]}
                    items={items}
                    style={{ lineHeight: '64px', width: '100%' }}
                />
                <Outlet />
            </div>
        </AppProvider>
    );
};

export default GeekLayout;
