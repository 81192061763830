import Client, { shared } from '@shoplinedev/appbridge';

// 创建 app 实例的函数
export const createAppInstance = () => {
    const app = Client.createApp({
        appKey: '1994b2c946a3528b1df2351e3592ca34c3418fea',
        host: shared.getHost() // 获取主机地址
    });
    return app;
}
