import { useState, useEffect } from 'react';
import CustomTable from '../../components/CustomTable';
import request from '../../utils/request';
import { useAppContext } from '../../utils/appContext';
import { Card, Skeleton, Row, Col, Statistic } from 'antd';


const PaymentDetails = () => {
  const [historyData, setHistoryData] = useState([]);
  const [loading, setLoading] = useState(true); // 初始化加载状态为true
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [totalPayPalInitiate, setTotalPayPalInitiate] = useState(0);
  const [totalPayPalSuccess, setTotalPayPalSuccess] = useState(0);

  // app实例
  const { app, token } = useAppContext();

  const fetchData = async () => {
    try {
      const startTime = Date.now(); // 记录开始时间
      const data = await request.get("v1/api/merchants/paymentDetails", {
        headers: {
          Authorization: `Bearer ${token}`
        },
        params: {
          page: currentPage,
          pageSize: pageSize,
        }
      });
      const processedData = data.data.map((item) => ({
        key: item.id,
        email: item.email,
        datetime: item.datetime,
        amount: item.amount,
        status: item.status,
      }));
      const elapsed = Date.now() - startTime;
      const minLoadingTime = 500; // 最小加载时间（毫秒）

      if (elapsed < minLoadingTime) {
        setTimeout(() => {
          setHistoryData(processedData);
          setTotalPayPalSuccess(data.paypalSuccessCount);
          setTotalPayPalInitiate(data.paypalDetailsCount);
          setTotal(data.total); // 更新总数
          setLoading(false); // 数据加载完成，设置加载状态为false
        }, minLoadingTime - elapsed);
      } else {
        setHistoryData(processedData);
        setTotalPayPalSuccess(data.paypalSuccessCount);
        setTotalPayPalInitiate(data.paypalDetailsCount);
        setTotal(data.total); // 更新总数
        setLoading(false); // 数据加载完成，设置加载状态为false
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [app, token, currentPage, pageSize]);

  // 表格列
  const columns = [
    { title: "客户邮箱", dataIndex: "email" },
    { title: "支付时间", dataIndex: "datetime" },
    // { title: "支付金额", dataIndex: "amount" },
    {
      title: "状态",
      dataIndex: "status",
      render: (text, record) => (
        <span
          className={record.status === 1 ? "ant-tag sl-tag sl-tag-success sl-tag-dot sl-tag-round" : "ant-tag sl-tag sl-tag-error sl-tag-dot sl-tag-round"}
        >
          {record.status === 1 ? "成功" : "失败"}
        </span>
      )
    }
  ];

  return (
    <div className="flex justify-center items-start pt-8">
      <div className="w-3/4">
        <Card title="支付详情" style={{ borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
          <Skeleton loading={loading} active>
            <Row gutter={16} className="mb-4">
              <Col span={12}>
                <Statistic title="今日PayPal发起结账" value={totalPayPalInitiate} valueStyle={{ color: '#cf1322' }} />
              </Col>
              <Col span={12}>
                <Statistic title="今日PayPal完成结账" value={totalPayPalSuccess} valueStyle={{ color: '#3f8600' }} />
              </Col>
            </Row>

            <h1 className="text-2xl font-bold text-center mb-6" style={{ color: '#4a90e2' }}>信用卡支付详情</h1>
            <CustomTable
              dataIndex={columns}
              dataSource={historyData}
              pagination={{
                current: currentPage,
                pageSize: pageSize,
                total: total,
                pageSizeOptions: ['10', '20', '50', '100'],
                showTotal: (total, range) => `总共 ${total} 条数据`,
                onChange: (page, pageSize) => {
                  setCurrentPage(page);
                  setPageSize(pageSize);
                },
              }}
            />
            <div className="mt-6">
              <p className="text-gray-600 text-center">
                注意：PayPal发起结账不代表支付失败。发起结账只是用户尝试支付的动作，最终支付成功的订单数量会显示在完成结账统计中。
              </p>
            </div>
        </Skeleton>
        </Card>
      </div>
    </div>
  );
};

export default PaymentDetails;
