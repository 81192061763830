import { Table, ConfigProvider, Empty } from "antd";
import zhCN from 'antd/es/locale/zh_CN';

const CustomTable = ({ dataSource, dataIndex, pagination }) => {
  return (
    <ConfigProvider locale={zhCN} theme={{ components: { Table: { headerBg: "#F5F8FC" } } }}>
      <Table 
        dataSource={dataSource} 
        borderColor="#000000"
        columns={dataIndex} 
        pagination={pagination} 
        locale={{ emptyText: <Empty description="暂无数据" image={Empty.PRESENTED_IMAGE_SIMPLE} />, }}
      />
    </ConfigProvider>
  );
}

export default CustomTable;
