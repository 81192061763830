import {createBrowserRouter} from "react-router-dom";
import CheckOauth from "../page/Oauth"
import App from "../page/Home/App";
import FacebookPixel from "../page/FacebookPixel";
import Layout from '../page/Layout/'
import FacebookAds from "../page/FacebookAds";
import Upsell from "../page/Upsell";
import PaymentDetails from "../page/PaymentDetails";
const  router = createBrowserRouter([
    {
        path:"/Oauth",
        element:<CheckOauth/>
    },
    {
        path:'/',
        element:<Layout/>,
        children:[
            {
                index:true,
                element:<App />,
                title: 'Home',
            },
            {
                path:'FacebookPixel',
                element:<FacebookPixel />
            },
            {
                path:'FacebookAds',
                element:<FacebookAds />
            },
            {
                path:'Upsell',
                element:<Upsell />
            },
            {
                path:'PaymentDetails',
                element:<PaymentDetails />
            }
        ]
    },
])

export default router