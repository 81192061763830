import { useState, useEffect, useRef } from 'react';
import CustomTable from '../../components/CustomTable';
import EditModal from '../../components/EditModal'; // 引入EditModal组件
import request from '../../utils/request';
import { useAppContext } from '../../utils/appContext';
import { Card, Button, Skeleton, Modal, Steps } from 'antd';
import { EditOutlined, DeleteOutlined, DownOutlined, UpOutlined } from '@ant-design/icons';
import { Message } from '@shoplinedev/appbridge';
import timezones from '../../utils/timezones'; // 引入timezones

const { Step } = Steps;



const FacebookAds = () => {
  const [historyData, setHistoryData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingRecord, setEditingRecord] = useState(null);
  const [isEditing, setIsEditing] = useState(false); // 用于区分添加和编辑操作
  const [loading, setLoading] = useState(true); // 初始化加载状态为true
  const [isGuideVisible, setIsGuideVisible] = useState(false); // 控制流程指引的可见性
  const formRef = useRef(); // 增加一个引用来重置表单
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(0)

  const fields = [
    { name: 'accountNumber', label: '账户编号', rules: [{ required: true, message: '请输入账户编号' }], placeholder: '请输入账户编号' },
    { name: 'timezone', label: '时区', rules: [{ required: true, message: '请选择时区' }], type: 'select', options: timezones, placeholder: '请选择时区' }
  ];

  // app实例
  const { app, token } = useAppContext();
  const message = Message.create(app);
  const fetchData = async () => {
    try {
      const startTime = Date.now(); // 记录开始时间
      const data = await request.get("v1/api/merchants/facebookAds", {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      const processedData = data.data.map((item) => ({
        key: item.id,
        timeZone: item.timeZone,
        id: item.id,
        accountNumber:item.id,
        timezone:item.timeZone
      }));
      const elapsed = Date.now() - startTime;
      const minLoadingTime = 500; // 最小加载时间（毫秒）

      if (elapsed < minLoadingTime) {
        setTimeout(() => {
          setHistoryData(processedData);
          setLoading(false); // 数据加载完成，设置加载状态为false
        }, minLoadingTime - elapsed);
      } else {
        setHistoryData(processedData);
        setLoading(false); // 数据加载完成，设置加载状态为false
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  useEffect(() => {

    fetchData();
  }, [app, token]);

  const showEditModal = (record) => {
    setEditingRecord(record);
    setIsEditing(true);
    setIsModalVisible(true);
  };

  const showAddModal = () => {
    setEditingRecord(null);
    setIsEditing(false);
    setIsModalVisible(true);
    formRef.current?.resetFields(); // 清除表单中的所有值
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setEditingRecord(null);
  };

  const handleSave = async (values) => {
    if (!values.accountNumber || !values.timezone) {
      return;
    }
    try {
      if (isEditing) {
        // 编辑操作
        const res = await request.post("v1/api/merchants/facebookAds", {
          ...values,
          action: 'update',
          id: editingRecord.id 
        }, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        message.open({
          type: res.code === 200 ? 'success' : 'error',
          messageInfo: res.code === 200 ? '更新成功' : '更新失败'
        });
      } else {
        // 添加操作
        const res = await request.post("v1/api/merchants/facebookAds", {
          ...values,
          action: 'insert'
        }, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        message.open({
          type: res.code === 200 ? 'success' : 'error',
          messageInfo: res.code === 200 ? '添加成功' : '添加失败'
        });
      }
      setIsModalVisible(false);
      fetchData(); // 重新获取数据
    } catch (error) {
      console.error("Error saving data: ", error);
    }
  };

  const handleDelete = async (key) => {
    try {
      const res = await request.post("v1/api/merchants/facebookAds", {
        action: 'delete',
        id: key
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      message.open({
        type: res.code === 200 ? 'success' : 'error',
        messageInfo: res.code === 200 ? '删除成功' : '删除失败'
      });
      setHistoryData(historyData.filter((item) => item.key !== key));
    } catch (error) {
      console.error("Error deleting data: ", error);
    }
  };

  const showDeleteConfirm = (key) => {
    Modal.confirm({
      title: '确认删除',
      content: '你确定要删除这条记录吗?',
      okText: '是',
      okType: 'danger',
      cancelText: '否',
      onOk() {
        handleDelete(key);
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  // 表格列
  const columns = [
    { title: "账户编号", dataIndex: "id" },
    { title: "账户时区", dataIndex: "timeZone" },
    {
      title: "操作",
      dataIndex: "action",
      render: (text, record) => (
        <>
          <EditOutlined onClick={() => showEditModal(record)} style={{ marginRight: 8 }} />
          <DeleteOutlined style={{color:"#FF522F"}} onClick={() => showDeleteConfirm(record.id)} /> 
        </>
      ),
      width: 100,
    },
  ];

  return (
    <div className="flex justify-center items-start pt-8">
      <div className="w-2/3">
        <Card title="Facebook 数据报告助手" extra={<Button type="primary"  onClick={showAddModal}>新增广告账户</Button>}>
        <Skeleton loading={loading} active>
          <div className="flex">
            <div className="w-1/3 p-4">
                <h3 className="text-xl font-bold">广告账户管理</h3>
                <p className="text-gray-600">Facebook数据上报助手来帮助商家解决因iOS14+隐私政策导致的Facebook广告数据缺失的问题，让广告主可以投放更有针对性的广告！</p>
 
            </div>
            <div className="w-2/3 p-4">
           
                <CustomTable
                  dataIndex={columns}
                  dataSource={historyData}
                  pagination={{
                    current: currentPage,
                    pageSize: pageSize,
                    total: total,
                    pageSizeOptions: ['10', '20', '50', '100'], 
                    showTotal: (total, range) => `总共 ${total} 条数据`,
                    onChange: (page, pageSize) => {
                      setCurrentPage(page);
                      setPageSize(pageSize);
            
                    },
                  }}
                />
            </div>
          </div>
          </Skeleton>
        </Card>
        
        <Card title="流程指引" className="mt-4"  extra={ <Button type="link" onClick={() => setIsGuideVisible(!isGuideVisible)}>{isGuideVisible ? <UpOutlined /> : <DownOutlined />}</Button>}>

          {isGuideVisible && (
            <div>
              <Steps direction="vertical" className="mt-4">
                <Step title="输入广告账户编号" description={<Button onClick={showAddModal} type="primary">新增广告账户</Button>} />
                <Step title="开启 Meta数据上报助手 Chrome浏览器插件" description={<Button type="primary" onClick={() => window.open('https://chromewebstore.google.com/detail/facebook%E6%95%B0%E6%8D%AE%E4%B8%8A%E6%8A%A5%E5%8A%A9%E6%89%8B/jclemjkbjncefbmalojocpmhnohnlhbp?authuser=0', '_blank')}>去安装</Button>} />
                <Step title="新增广告" description={<>创建广告后，在目标网址后添加以下标记：<br /><code>?campaign_id={'{{'}campaign.id{'}'}&adset_id={'{{'}adset.id{'}'}&ad_id={'{{'}ad.id{'}'}</code><br /></>} />
                <Step title="到广告分析页面，展示重要数据指标" description="例如：广告点击量、广告展示次数等。" />
                <Step title="设置完成，查看效果" />
              </Steps>
            </div>
          )}
        </Card>
      </div>
      <EditModal
        title={isEditing ? "编辑账户" : "添加账户"} 
        visible={isModalVisible}
        onCancel={handleCancel}
        onSave={handleSave}
        record={editingRecord}
        fields={fields}
        formRef={formRef}
      />
    </div>
  );
};

export default FacebookAds;
