import React, { useEffect, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import getLineChartOption from '../../utils/getLineChartOption';
import getPieChartOption from '../../utils/getPieChartOption';
import DateSelector from '../../components/DateSelector';
import request from '../../utils/request';
import { Skeleton, Spin } from 'antd';
import { useAppContext } from '../../utils/appContext';
import { Message } from '@shoplinedev/appbridge';

const Dashboard = () => {
    const [loading, setLoading] = useState(true); // 控制加载状态
    const [spinLoading, setSpinLoading] = useState(false); 
    const [dateRange, setDateRange] = useState([null, null]);
    const { app, token } = useAppContext();
    const [totalIncomeData, setTotalIncomeData] = useState([]);
    const [upsellIncomeData, setUpsellIncomeData] = useState([]);
    const [xAxisData, setXAxisData] = useState([]);
    const [orderNum, setOrderNum] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const [upsellAmount, setupsellAmount] = useState(0);
    const [upsellOrderNum, setUpsellOrderNum] = useState(0);
    const [title, setTitle] = useState('')
    const message = Message.create(app);
    const fetchData = async () => {
        if(dateRange[0] != null || dateRange[1] != null){
            setSpinLoading(true);
        }
        try {
            const data = await request.get('v1/api/merchants/shop_info', {
                headers: {
                    Authorization: `Bearer ${token}`
                },
                params: {
                    startDate: dateRange[0]?.format('YYYY-MM-DD'),
                    endDate: dateRange[1]?.format('YYYY-MM-DD'),
                }
            });

            const orders = data.orders || [];
            const upsellOrders = data.upsellOrders || [];
            const incomeData = orders.map(order => parseFloat(order.current_total_price));
            const upsellIncomeData = upsellOrders.map(order => parseFloat(order.current_total_price));
            const dateData = orders.map(order => new Date(order.created_at).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }));

            setTotalIncomeData(incomeData);
            setUpsellIncomeData(upsellIncomeData);
            setXAxisData(dateData);
            setOrderNum(data.data.orderNum);
            setTotalAmount(data.data.totalAmount);
            setUpsellOrderNum(data.data.upsellNum);
            setTitle(data.data.title);
            setupsellAmount(data.data.upsellAmount);
            setLoading(false);
            setSpinLoading(false);
        } catch (error) {
            console.error('Error fetching data: ', error);
            message.open({
                type: 'error',
                messageInfo: '网络异常，请稍后再试',
              });
            setLoading(false);
            setSpinLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [dateRange]);

    const option1 = getLineChartOption({
        titleText: '总收入',
        dataSeries: totalIncomeData,
        xAxisData: xAxisData,
    });

    const option2 = getLineChartOption({
        titleText: '追加销售收入',
        dataSeries: upsellIncomeData,
        xAxisData: xAxisData,
    });

    return (
        <div className="min-h-screen pt-10">
            <div className="max-w-6xl mx-auto px-4">
            <Skeleton loading={loading} >
            <p class="font-bold mb-4">{title}</p>
                <DateSelector onDateChange={setDateRange} />
                </Skeleton>
                <Spin tip="Loading..." spinning={spinLoading}>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 text-white" style={{marginTop:10}}>
                    <div style={{ backgroundColor: '#333333' }} className="p-6 rounded-lg shadow-lg flex items-center justify-between">
                        <Skeleton loading={loading} >
                        
                            <div className="text-center">
                                <h2 className="text-1xl font-bold">${totalAmount}</h2>
                                <span className="text-lg">订单金额</span>
                            </div>
                            <div className="h-full w-px bg-white mx-4"></div>
                            <div className="text-center">
                                <h2 className="text-1xl font-bold">{orderNum}</h2>
                                <span className="text-lg">订单数量</span>
                            </div>
                        </Skeleton>
                    </div>
                    
                    <div style={{ backgroundColor: '#333333' }} className="p-6 rounded-lg shadow-lg flex items-center justify-between">
                        <Skeleton loading={loading} >
                            <div className="text-center">
                                <h2 className="text-1xl font-bold">${upsellAmount}</h2>
                                <span className="text-lg">追加销售收入</span>
                            </div>
                            <div className="h-full w-px bg-white mx-4"></div>
                            <div className="text-center">
                                <h2 className="text-1xl font-bold">{upsellOrderNum}</h2>
                                <span className="text-lg">追加销售订单数量</span>
                            </div>
                        </Skeleton>
                    </div>
                </div>
              
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                    <div className="bg-white p-4 rounded-lg shadow-lg">
                        <Skeleton loading={loading} >
                            <ReactECharts option={option1} style={{ height: 400 }} />
                        </Skeleton>
                    </div>
                    <div className="bg-white p-4 rounded-lg shadow-lg">
                        <Skeleton loading={loading} >
                            <ReactECharts option={option2} style={{ height: 400 }} />
                        </Skeleton>
                    </div>
                </div>
                </Spin>
            </div>
        </div>
    );
};

export default Dashboard;
