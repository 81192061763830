import React, { createContext, useContext, useMemo } from 'react';

// 创建 Context 对象
const AppContext = createContext(null);

export const useAppContext = () => {
    return useContext(AppContext);
};

export const AppProvider = ({ children, app, token }) => {
    // 使用 useMemo 确保 value 在 app 和 token 不变时保持稳定
    const value = useMemo(() => ({ app, token }), [app, token]);

    return (
        <AppContext.Provider value={value}>
            {children}
        </AppContext.Provider>
    );
};
