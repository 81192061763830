import { useState, useEffect, useRef } from 'react';
import CustomTable from '../../components/CustomTable';
import EditModal from '../../components/EditModal'; // 引入EditModal组件
import request from '../../utils/request';
import { useAppContext } from '../../utils/appContext';
import { Card, Button, Skeleton, Modal, Image, Tooltip } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Message, ResourcePicker } from '@shoplinedev/appbridge';

const Upsell = () => {
  const [historyData, setHistoryData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingRecord, setEditingRecord] = useState(null);
  const [isEditing, setIsEditing] = useState(false); // 用于区分添加和编辑操作
  const [selectedProducts, setSelectedProducts] = useState([]); // 用于存储选择的产品
  const [loading, setLoading] = useState(true); // 初始化加载状态为true
  const formRef = useRef(); // 增加一个引用来重置表单
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(0)
  const fields = [
    { name: 'price', label: '折扣价格(百分比)', rules: [{ required: true, message: '请输入折扣价格' }], placeholder: '折扣价格(百分比)' },
  ];
  // app实例
  const { app, token } = useAppContext();
  const message = Message.create(app);

  const fetchData = async () => {
    try {
      const startTime = Date.now(); // 记录开始时间
      const data = await request.get("v1/api/merchants/upsellPorduct", {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      const processedData = data.data.map((item) => ({
        key: item.id,
        title: item.title,
        image_url: item.image_url,
        price: item.price,
        id: item.id
      }));
      const elapsed = Date.now() - startTime;
      const minLoadingTime = 500; // 最小加载时间（毫秒）

      if (elapsed < minLoadingTime) {
        setTimeout(() => {
          setHistoryData(processedData);
          setLoading(false); // 数据加载完成，设置加载状态为false
        }, minLoadingTime - elapsed);
      } else {
        setHistoryData(processedData);
        setLoading(false); // 数据加载完成，设置加载状态为false
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [app, token]);

  const showEditModal = (record) => {
    setEditingRecord(record);
    setIsEditing(true);
    setIsModalVisible(true);
    setTimeout(() => {
      formRef.current?.setFieldsValue(record); // 设置表单中的值
    }, 0);
  };

  const showAddModal = (products = []) => {
    setSelectedProducts(products);
    setEditingRecord(null);
    setIsEditing(false);
    setIsModalVisible(true);
    setTimeout(() => {
      formRef.current?.resetFields(); // 清除表单中的所有值
    }, 0);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setEditingRecord(null);
    setSelectedProducts([]); // 重置选择的产品
  };

  const handleSave = async (values) => {
    try {
      if (isEditing) {
        // 编辑操作
        const res = await request.post("v1/api/merchants/upsellPorduct", {
          ...values,
          action: 'update',
          id: editingRecord.id 
        }, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        message.open({
          type: res.code === 200 ? 'success' : 'error',
          messageInfo: res.code === 200 ? '更新成功' : '更新失败'
        });
      } else {
        // 添加操作
        const res = await request.post("v1/api/merchants/upsellPorduct", {
          ...values,
          selectedProducts, // 将选择的产品传递给后端
          action: 'insert'
        }, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        let messageInfo = '';
        if (res.code === 200) {
          const { repeatCount, successCount } = res;
          messageInfo = `添加成功 ${successCount} 条，重复添加 ${repeatCount} 条`;
        } else {
          messageInfo = '添加失败';
        }
  
        message.open({
          type: res.code === 200 ? 'success' : 'error',
          messageInfo: messageInfo
        });
      }
      setIsModalVisible(false);
      fetchData(); // 重新获取数据
    } catch (error) {
      console.error("Error saving data: ", error);
    }
  };

  const handleDelete = async (key) => {
    try {
      const res = await request.post("v1/api/merchants/upsellPorduct", {
        action: 'delete',
        id: key
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      message.open({
        type: res.code === 200 ? 'success' : 'error',
        messageInfo: res.code === 200 ? '删除成功' : '删除失败'
      });
      setHistoryData(historyData.filter((item) => item.key !== key));
    } catch (error) {
      console.error("Error deleting data: ", error);
    }
  };

  const showDeleteConfirm = (key) => {
    Modal.confirm({
      title: '确认删除',
      content: '你确定要删除这条记录吗?',
      okText: '是',
      okType: 'danger',
      cancelText: '否',
      onOk() {
        handleDelete(key);
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const addProduct = () =>{
    const resourcePicker = ResourcePicker.create(app)
        // 打开资源选择器
        resourcePicker.open({
          type: 'Product'
        })
      // 点击 选择/添加 按钮后进行的回调操作
      resourcePicker.subscribe(ResourcePicker.ACTION.SELECTED, ({selectedList}) => {
        // selectedList数据为勾选的对应行数据
        console.log(selectedList)
        showAddModal(selectedList)
      })
  }

  // 表格列
  const columns = [
    { 
      title: "商品名称", 
      dataIndex: "title",
      render: (text, record) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Image src={record.image_url} alt={text} width={50} height={50} />
          <Tooltip title={text}>
            <span style={{ color: '#636A77', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', maxWidth: '500px', marginLeft: 8 }}>
              {text}
            </span>
          </Tooltip>
        </div>
      )
    },
    { title: "折扣价格", dataIndex: "price" },
    {
      title: "操作",
      dataIndex: "action",
      render: (text, record) => (
        <>
          <EditOutlined onClick={() => showEditModal(record)} style={{ marginRight: 8 }} />
          <DeleteOutlined style={{color:"#FF522F"}} onClick={() => showDeleteConfirm(record.id)} />
        </>
      ),
      width: 100,
    }
  ];

  return (
    <div className="flex justify-center items-start pt-8">
      <div className="w-2/3">
        <Card title={'商品追加销售'} extra={<Button type="primary" onClick={addProduct}>新增产品</Button>}>
          <Skeleton loading={loading} active>
            <div className="flex">
              <div className="w-1/3 p-4">
                <h3 className="text-xl font-bold">结账漏斗</h3>
                <p className="text-gray-600">“结帐”按钮的漏斗:当客户完成付款后,如该商品开启了追购设置即会进入追购页面。</p>
              </div>
              <div className="w-2/3 p-4">
                <CustomTable
                  dataIndex={columns}
                  dataSource={historyData}
                  pagination={{
                    current: currentPage,
                    pageSize: pageSize,
                    total: total,
                    pageSizeOptions: ['10', '20', '50', '100'], 
                    showTotal: (total, range) => `总共 ${total} 条数据`,
                    onChange: (page, pageSize) => {
                      setCurrentPage(page);
                      setPageSize(pageSize);
            
                    },
                  }}
                />
              </div>
            </div>
          </Skeleton>
        </Card>
      </div>
      <EditModal
        title={isEditing ? "编辑产品" : "添加产品"}
        visible={isModalVisible}
        onCancel={handleCancel}
        onSave={handleSave}
        record={editingRecord}
        fields={fields}
        formRef={formRef} 
      />
    </div>
  );
};

export default Upsell;
