const getLineChartOption = ({ titleText, dataSeries, xAxisData }) => {
    return {
        tooltip: {
            trigger: 'axis'
        },
        xAxis: {
            type: 'category',
            data: xAxisData,
            axisLabel: {
                color: '#000' // 黑色X轴标签
            },
        },
        yAxis: {
            type: 'value',
            axisLabel: {
                color: '#000' // 黑色Y轴标签
            },
        },
        title: {
            text: titleText,
            textStyle: {
                color: '#000' // 黑色标题
            },
        },
        series: [
            {
                data: dataSeries,
                type: 'line',
                itemStyle: {
                    color: '#546E7A' // 指定线的颜色
                },
            },
        ],
    };
};

export default getLineChartOption