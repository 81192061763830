import React, { useState } from 'react';
import {DatePicker, Select} from 'antd';
import dayjs from 'dayjs';
import locale from 'antd/es/date-picker/locale/zh_CN';
import 'dayjs/locale/zh-cn';
const { RangePicker } = DatePicker;
const { Option } = Select;

const DateSelector = ({onDateChange}) => {
    const [dateRange, setDateRange] = useState([dayjs(), dayjs()]);
    const handleDateChange = (dates, dateStrings) => {
        setDateRange(dates)
        onDateChange(dates)
    };
    const handleQuickSelect = (value) => {
        let range;
        switch (value) {
            case 'today':
                range = [dayjs(), dayjs()];
                break;
            case 'yesterday':
                range = [dayjs().subtract(1, 'day'), dayjs().subtract(1, 'day')];
                break;
            case 'last7days':
                range = [dayjs().subtract(6, 'days'), dayjs()];
                break;
            case 'last30days':
                range = [dayjs().subtract(29, 'days'), dayjs()];
                break;
            default:
                range = [];
        }

        setDateRange(range);
        handleDateChange(range, range.map(date => date.format('YYYY-MM-DD')));
    };

    return (

        <div>
                <Select defaultValue="today" style={{ width: 120, marginBottom: 10, marginRight:10 }} onChange={handleQuickSelect}>
                    <Option value="today">今天</Option>
                    <Option value="yesterday">昨天</Option>
                    <Option value="last7days">最近7天</Option>
                    <Option value="last30days">最近30天</Option>
                </Select>
                <RangePicker
                    placeholder={['开始日期', '结束日期']}
                    value={dateRange}
                    onChange={handleDateChange}
                    locale={locale}
                />
        </div>
    );
};

export default DateSelector;
